<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <input
                v-model="number"
                type="number"
                class="input-task-number"
                :placeholder="jobRepeat"
              >
              <p class="mt-2">
                <input
                  id="input-delete-checkbox"
                  type="checkbox"
                  value="delete"
                  @click="getDeleteValue"
                >
                <label for="input-delete-checkbox" />
                <span>&nbsp;태스크 삭제</span>
              </p>
            </slot>
          </div>

          <v-row class="modal-footer ma-0 pa-0">
            <v-col
              class="btn-cancel pa-0"
              cols="6"
              type="button"
              @click="$emit('close')"
            >
              취소
            </v-col>
            <v-col
              class="btn-action pa-0"
              cols="6"
              type="button"
              @click="$emit('action', { isChecked, number })"
            >
              변경
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'TaskEditModal',
    props: {
      jobRepeat: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        isChecked: false,
        number: null,
      }
    },
    methods: {
      getDeleteValue() {
        this.isChecked = !this.isChecked;
      }
    },
  }
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  word-break: keep-all;
}

.modal-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  height: 50%;
  margin: 0 auto;
  background-color: #393939;
  border-radius: 10px;
  transition: all .3s ease;
}

.modal-body {
  width: 100%;
  height: 35vh;
  padding: 12vh 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  text-align: center;
  color: #ffffff;
  overflow: auto;
}

.modal-text {
  margin: 50% 0 0;
}

.btn-cancel {
  text-align: center;
  background-color: #c4c4c4;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.btn-action {
  text-align: center;
  background-color: #7c7c7c;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.input-task-number {
  width: 60%;
  height: 8vh;
  margin: 1vh 0 0;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  text-align: right;
  font-family: Noto Sans KR,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vw;
  color: white;
}

input[type='checkbox'] {
  display: none;
}

input[type="checkbox"] + label{
  display: inline-block;
  position: relative;
  width: 3vw;
  height: 3vw;
  cursor:pointer;
  border: 2px solid #545861;
  background-color: black;
  vertical-align: middle;
}

input[id="input-delete-checkbox"]:checked + label::after{
  content:'✔';
  font-size: 2vw;
  color: #41bfb4;
  width: 3vw;
  height: 3vw;
  cursor:pointer;
  text-align: center;
  position: absolute;
  left: 0;
  top:0;
}

</style>
