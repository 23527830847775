<template>
  <v-col v-if="jobs.length">
    <default-modal
      v-if="showModal"
      :action="modalState.action"
      :cancel-message="modalState.cancelMessage"
      :message="modalState.message"
      @action="doAction"
      @close="closeModal"
    />
    <v-col cols="12">
      <h1 class="title-task">
        진행중 태스크
      </h1>
    </v-col>
    <template v-if="!$store.state.isTaskStop">
      <v-col class="pa-0">
        <v-card
          class="task"
          color="#d4d4d4"
        >
          <v-row justify="space-between">
            <v-col>
              <span class="ml-5">
                {{ workName }}
              </span>
            </v-col>
            <v-col>
              <span class="ml-5">
                {{ progress }}
              </span>
            </v-col>
            <v-spacer/>
            <v-col>
              <v-btn
                class="btn-task"
                color="#00BFB4"
                dark
                height="70px"
                @click="clickTaskPauseBtn"
              >
                정지
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-col class="pa-0">
        <v-card
          class="task"
          color="#d4d4d4"
        >
          <v-row justify="space-between">
            <v-col>
              <span class="ml-5">
                {{ workName }}
              </span>
            </v-col>
            <v-col>
              <span class="ml-5">
                {{ progress }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                class="btn-task"
                color="#00BFB4"
                dark
                height="70px"
                @click="clickTaskPauseBtn"
              >
                재개
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="btn-task"
                color="#7c7c7c"
                dark
                height="70px"
                @click="cancelTask"
              >
                취소
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
  </v-col>
</template>

<script>
import DefaultModal from '@/components/modals/default_modal.vue';
import EventBus from '@/main.js';
import arrival from "@/assets/audio/arrival2.mpeg";
import depart from "@/assets/audio/depart2.mpeg";

export default {
  name: 'Progress',
  components: {
    DefaultModal,
  },
  data() {
    return {
      jobs: [],
      jobId: null,
      workName: null,
      progress: '',
      showModal: false,
      modalState: {
        message: null,
        action: null,
        cancelMessage: null,
      },
    }
  },
  created() {
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('showTaskName');
    EventBus.$off('job:goto_location_id/progress');
    EventBus.$off('job:work_finish/progress');
    EventBus.$off('job:update/progress');
  },
  methods: {
    initialize() {
      EventBus.$off('showTaskName');
      EventBus.$off('job:goto_location_id/progress');
      EventBus.$off('job:work_finish/progress');
      EventBus.$off('job:update/progress');

      EventBus.$on('showTaskName', data => {
        this.workName = data;
      });
      EventBus.$on('job:goto_location_id/progress', (data)=> {
        const workId = data.split("/")[1];
        this.$axios.get(`/work/${workId}`)
          .then(res => {
            if (res.status === 200) {
              this.workName = res.data[0].work_name;
            }
          })
          .catch(err => {throw Error(err)});
      });
      EventBus.$on('job:work_finish/progress', (data)=> {
        this.fetchJob(data).catch(err=> {throw Error(err)});
      });

      EventBus.$on('job:update/progress', (data)=> {
        this.fetchJob(data).catch(err=> {throw Error(err)});
      });

      this.fetchJob(true);
    },
    clickTaskPauseBtn() {
      this.$store.state.isTaskStop ? this.$store.commit('reStartTask') : this.$store.commit('stopTask');

      if (this.$store.state.isTaskStop) {
        return this.fetchPause();
      } else {
        this.fetchResume();
      }
    },
    fetchPause() {
      return this.$axios.put('/job/pause')
        .catch(err => {
          throw Error(err);
        });
    },
    fetchResume() {
      return this.$axios.put('/job/resume')
        .catch(err => {
          throw Error(err);
        });
    },
    cancelTask() {
      this.modalState.message = '진행중인 태스크를 취소하시겠습니까?';
      this.modalState.action = '태스크 취소';
      this.modalState.cancelMessage = '아니오';
      this.showModal = true;
    },
    fetchJob(voidAudio) {
      return this.$axios.get('/job')
        .then(res => {
          if (res.status === 200) {
            this.jobs = res.data;
            if (this.jobs.length === 0) {
              if(this.jobId&& !voidAudio){
                this.jobId = null;
                let arrivalAudio = new Audio(arrival);
                arrivalAudio.play();
              }
              this.$store.commit('reStartTask');
            } else {
              if(!voidAudio){
                let departAudio = new Audio(depart);
                departAudio.play();
              }
              this.jobId = this.jobs[0].job_id;
              this.progress = `(${this.jobs[0].job_done_count}/${this.jobs[0].job_repeat})`
            }
            EventBus.$emit('socketEmit', 'job:goto_location_id');
          }
        });
    },
    doAction() {
      if (this.modalState.action === '태스크 취소') {
        this.showModal = false;
        const id = this.jobId;
        this.$store.commit('reStartTask');

        return this.$axios.delete(`/job/${id}`)
          .then(()=> this.fetchJob(true))
          .then(()=> {
            EventBus.$emit('clearTaskInfo');
            EventBus.$emit('socketEmit', 'changeJob');
          })
          .catch(err => {
            throw Error(err);
          });
      }
      this.closeModal();
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.modalState.cancelMessage = null;
      this.showModal = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.title-task {
  color: #ffffff;
  font-size: 50px;
}

.task {
  border-radius: 15px !important;
  vertical-align: middle;
  font-size: 50px;
  color: #7c7c7c !important;
}

.btn-task {
  width: 80%;
  border-radius: 10px;
  font-size: 30px !important;
  font-weight: bold !important;
}

@media (max-width: 1700px) {
  .task {
    height: 50px;
    font-size: 30px;
  }

  .task > .row > .col {
    padding: 0 !important;
  }

  .btn-task {
    height: 40px !important;
    font-size: 30px !important;
  }
}

@media (max-width: 1140px) {
  .title-task {
    font-size: 16px;
  }

  .task {
    height: 35px;
    font-size: 20px;
  }

  .btn-task {
    height: 25px !important;
    font-size: 11px !important;
  }
}
</style>
